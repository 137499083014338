<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="10" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(assign)" @reset.prevent="reset" >
                    <ValidationProvider name="Project" vid="org_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('pump_install.project')"
                        label-for="project_id"
                        slot-scope="{ valid, errors }"
                        >
                        <b-form-select
                          plain
                          v-model="assign_project.project_id"
                          :options="schemeProjectList"
                          id="project_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { schemeAssignProject } from '../../api/routes'

export default {
  props: ['id', 'org_id', 'status'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.$t('globalTrans.save'),
      assign_project: {
        project_id: 0,
        scheme_application_id: this.$props.id,
        status: this.$props.status
      },
      schemeProjects: []
    }
  },
  computed: {
    schemeProjectList: function () {
      const projectList = this.$store.state.IrriConfig.commonObj.projectList
      return projectList.filter(item => item.status === 0 && item.org_id === this.org_id)
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    async assign () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      result = await RestApi.postData(irriSchemeServiceBaseUrl, schemeAssignProject, this.assign_project)
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-1')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
