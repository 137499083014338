<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <b-overlay :show="loading">
              <template>
                  <b-row>
                      <b-col lg="12" sm="12" class="text-center mb-4">
                        <h5 class='complain-title'>{{ $t('pump_install.technical_survey_report') }}</h5>
                      </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="12" sm="12">
                      <b-row>
                        <b-col lg="3" sm="12">
                          <p class="text-dark">{{ $t('pump_install.application_id') }} : </p>
                        </b-col>
                        <b-col lg="9" sm="12">
                          <p class="text-dark">{{ survey.application_id }}</p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="3" sm="12">
                          <p class="text-dark">{{ $t('pump_install.survey_date') }} : </p>
                        </b-col>
                        <b-col lg="9" sm="12">
                          <p class="text-dark">{{ survey.survey_date | dateFormat }}</p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                      <b-col lg="12" sm="12" class="text-center mb-4">
                        <h5 class='complain-title'>{{ $t('pump_install.suggestion') }}</h5>
                      </b-col>
                  </b-row>
                  <b-row class="mb-3">
                      <b-col lg="12" sm="12">
                        <span class="text-dark">{{ ($i18n.locale == 'bn') ? survey.suggestion_bn : survey.suggestion }}</span>
                      </b-col>
                  </b-row>
                  <b-row>
                      <b-col lg="12" sm="12" class="text-center">
                        <h5 class='complain-title'>{{ $t('pump_install.note') }}</h5>
                      </b-col>
                  </b-row>
                  <b-row>
                      <b-col lg="12" sm="12">
                        <b-table-simple striped bordered small>
                          <b-tr>
                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th class="text-center">{{ $t('pump_install.note') }}</b-th>
                          </b-tr>
                          <b-tr v-for="(survey_note,index) in survey_notes" :key="index">
                            <b-td>{{ $n(index + 1) }}</b-td>
                            <b-td>{{ ($i18n.locale == 'bn') ? survey_note.note_bn : survey_note.note }}</b-td>
                          </b-tr>
                        </b-table-simple>
                      </b-col>
                  </b-row>
              </template>
            </b-overlay>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { getSchemeSurveyReport, getSchemeNote } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    if (this.id) {
      this.getSurveyNote()
      this.getSurveyReport()
    }
  },
  data () {
    return {
      loading: false,
      survey: '',
      survey_notes: [],
      scheme_application_id: this.$props.id,
      base_url: irriSchemeServiceBaseUrl
    }
  },
  methods: {
    async getSurveyReport () {
      this.loading = true
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, getSchemeSurveyReport, { scheme_application_id: this.scheme_application_id })
      if (result.success) {
        this.survey = result.data
      }
    },
    async getSurveyNote () {
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, getSchemeNote, { scheme_application_id: this.scheme_application_id })
      if (result.success) {
        this.survey_notes = result.data
        this.loading = false
      }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
